import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import Layout from 'screens/public/layout/layout';
import { HomeService } from 'services';
import { Constants, Globals } from 'utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-element.css';
import { Pagination, Autoplay } from 'swiper';
import { WhiteImage, BtnHeaderImage, HeaderImage } from 'assets/img';
import SliderSecondary from '../layout/slider-secondary';
import ProductItem from '../layout/product-item';
import SliderPrimary from '../layout/slider-primary';
import { Modal, Pagination as PagePagination } from 'components';
import { LikeIcon } from 'assets/icons';
import queryString from 'query-string';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class PublicHome extends React.Component<Props> {

	state = {
		combos: [],
		products: [],
		loading: true,
		visible: false,
		delivery_type_id: '',
		activeFixed: false,
		page: 1,
		last_page: 1,
	}

	componentDidMount() {
		window.addEventListener("scroll", (e) => {
			if (window.scrollY) {
				this.setState({
					activeFixed: window.scrollY > 420
				});
			}
		});

		const {
			success,
			delivery_type_id
		} = queryString.parse(this.props.location.search);

		if (success) {
			this.setState({
				visible: true,
				delivery_type_id
			});
			this.props.history.replace('/');
		}

		this.load();
	}
	
	load = async (withoutLoading: boolean = false) => {
		const res: any = await HomeService.public.getProducts({
			withoutLoading: true,
			page: this.state.page
		});
		this.setState({
			combos: res.combos || [],
			loading: false,
			products: res.products.rows,
			last_page: res.products.count,
		});
	}

	onClose = () => {
		this.setState({
			visible: false
		});
	}

	render() {
		const { visible } = this.state;
		const advertising_banners = this.props.banners.filter((i: any) => i.type == Constants.BANNERS.ADVERTISING);

		return (
			<Layout>
				<div id="public-home">
					<Modal
			          className="modal-success"
			          visible={ visible }
			          title=""
			          withoutClose={ true }
			          centered
			          onClose={ () => this.onClose() }
			        >
			        	<div id="modal-success">
			        		<img src={ LikeIcon } />
			        		<h3>Su compra ha sido exitosa</h3>
			        		{
			        			// @ts-ignore
			        			this.state.delivery_type_id == Constants.DELIVERY_TYPES.PICKUP && (
			        				<React.Fragment>
			        					<p>Lo esperamos en nuestras instalaciones para que retire su compra</p>
			        					<p>Estaremos disponibles hasta las 9:00pm</p>
			        				</React.Fragment>
			        			)
			        		}			        		
			        		{
			        			// @ts-ignore
			        			this.state.delivery_type_id == Constants.DELIVERY_TYPES.DELIVERY && (
			        				<React.Fragment>
			        					<p>Próximamente realizaremos su envío, cualquier información adicional que necesitemos le contactaremos.</p>
			        				</React.Fragment>
			        			)
			        		}
			        		<button onClick={ () => this.onClose() }>
			        			Entendido
			        		</button>
			        	</div>
			      	</Modal>

					{/*<SliderPrimary />*/}

		            <div className="row">
		            	<div className="col-md-3 text-center">
	            			<div className={ `container-fixed-advertisings ${ this.state.activeFixed ? 'active' : '' }` }>
		            			{
			            			advertising_banners.map((item: any) => {
			            				return (
			            					<a href={ item?.url } target="_blank" onClick={ (e: any) => {
												if (!item?.url) {
													e.preventDefault();
												}
											} }>
						                    	<img className="item-anuncio" src={ Constants.BASE_STORAGE + item?.file } />
						                    </a>
			            				)
			            			})
			            		}
			            	</div>
		            	</div>
		            	<div className="col-md-9">
		            		<div className="container-header" style={{ backgroundImage: `url(${ HeaderImage })` }}>
								{/*{
									this.props.user == null && (
										<Link to="/login">
											<img src={ BtnHeaderImage } />
										</Link>
									)
								}*/}
							</div>

		            		<h3>Productos</h3>

		            		{
		            			this.state.loading ? (
		            				<div className="container-loading">
		            					<i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
		            				</div>
		            			) :  (
		            				<div className="row row-products">
		            					{
					            			this.state.products.map((item: any) => {
					            				return (
					            					<div className="col-md-3">
					            						<Link to={ `/view-product/${ item?.id }` } className="link-product">
							            					<ProductItem item={ item } />
							            				</Link>
					            					</div>
					            				)
					            			})
					            		}

					            		{
					            			this.state.products.length == 0 && (
					            				<p className="message-no-items">Lo sentimos, no se han encontrado productos disponibles</p>
					            			)
					            		}
		            				</div>
		            			)
		            		}

		            		<PagePagination 
								pages={ this.state.last_page } 
								active={ this.state.page }
								onChange={ async (page: number) => {
									await this.setState({
										page: page
									});
									this.load();
								} } />

		            		<SliderSecondary />

		            		<h3>Combos</h3>

		            		{
		            			this.state.loading ? (
		            				<div className="container-loading">
		            					<i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
		            				</div>
		            			) :  (
		            				<div className="row row-products">
		            					{
					            			this.state.combos.map((item: any) => {
					            				return (
					            					<div className="col-md-6">
					            						<Link to={ `/view-product/${ item?.id }` } className="link-product">
							            					<ProductItem item={ item } className="item-combo" />
						            					</Link>
					            					</div>
					            				)
					            			})
					            		}

					            		{
					            			this.state.combos.length == 0 && (
					            				<p className="message-no-items">Lo sentimos, no se han encontrado combos disponibles</p>
					            			)
					            		}
		            				</div>
		            			)
		            		}
		            	</div>
		            </div>
				</div>
			</Layout>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user,
		banners: state.banners
	}
})(PublicHome);